
.verification-list {
  padding: 2rem 0;

  @include responsive(sm) {
    max-width: $sm-max-width;
    margin-left: auto;
    margin-right: auto;
  }
}
