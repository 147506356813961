/*------------------------------------*\
  # FORM
\*------------------------------------*/

/**
 * Default styles for forms.
 */


form {}

fieldset {
    border: 0;
}

input[type="number"],
input[type="text"],
input[type="password"],
select {
    color: $color-white;
    min-height: 3rem;
    padding: 1rem;
    background-color: $color-black;
    border: none;
    width: 100%;
}

select {
    padding: 1rem 0.7rem;
}

input:-internal-autofill-selected {
    color: $color-white !important;
    background-color: $color-black !important;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $color-white;
    opacity: 1; /* Firefox */
}

input[type="number"],
input[type="text"],
input[type="password"],
textarea {
    box-sizing: border-box;
    background-color: transparent;

    &:focus {
        box-shadow: none;
        outline: none;
        transition: outline .3s $ease-in-out;
    }
}

input:not([type=checkbox]):not([type=radio]) {
    appearance: none;
    border-radius: 0;
}

.form {
    padding: 0rem 2rem;

    @include responsive(sm) {
        max-width: $sm-max-width;
        margin-left: auto;
        margin-right: auto;
    }

    &__element {
        width: 100%;
        display: block;
        border-bottom: 1px solid $color-white;
        margin-bottom: 2rem;

        input[type="text"],
        input[type="password"] {
            padding: 2rem 0;
        }
    }

    &__tickbox {
        display: flex;
        align-items: center;
        margin-bottom: 2rem;
    }

    &__tickbox-label {
        line-height: 2rem;
    }

    &__tickbox-icon {
        display: inline-block;
        width: 2rem;
        height: 2rem;
        border-radius: 1rem;
        border: 0.1rem solid $color-white;
        margin-right: 1rem;
    }
}
