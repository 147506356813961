
@keyframes spinner {
  to {transform: rotate(360deg);}
}

.loader {
    display: flex;
    height: 100%;
    justify-content: center;
    font-size: 4rem;
    align-items: center;

    i {
      animation: spinner .6s linear infinite;
    }
}
