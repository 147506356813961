.email-address {
  &__form {
    display: flex;
    flex: 1;
    justify-content: center;
  }

  // todo: try remove inputs
  &__inputs {
    width: 4.5rem !important;
    min-height: 4.5rem !important;
    padding: 0 !important;
    margin: 0.5rem;
    border: 0;
    text-align: center;
    border-radius: 4.5rem !important;
    background-color: $color-white !important;
    color: $color-black !important;

    &:focus {
      outline: 0 !important;
      box-shadow: none !important;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }
}
