.identification {
  height: calc(100% - 2rem);

  &__form {
    margin: 8rem 0 2rem 0;
    padding: 0rem 2rem;

    @include responsive(sm) {
      max-width: $sm-max-width;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__title {
    text-align: center;
    font-weight: $font-weight-bold;
    margin-bottom: 2rem;
  }

  &__notice {
    padding: 0 6rem;
    text-align: center;
    font-size: 1.2rem;

    @include responsive(sm) {
      max-width: $sm-max-width;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__types {
    padding: 0 2rem;

    @include responsive(sm) {
      max-width: $sm-max-width;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__icon-button {
    border: 0.1rem solid $color-darkGre;
    background-color: $color-lighterlack;
    text-align: center;
    padding: 4rem 2rem;
    margin-bottom: 2rem;
    font-size: 2rem;
    font-weight: bold;

    & > i {
      display: block;
      font-size: 4rem;
      margin-bottom: 1rem;
    }
  }

  &__overview {
    padding: 0 2rem;

    @include responsive(sm) {
      max-width: $sm-max-width;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__bullet-list {
    margin: 0;
    padding: 2rem 2rem;
  }

  &__bullet-item {
    margin-bottom: 1rem;
  }

  &__photo {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
  }

  &__photo-buttons {
    position: absolute;
    z-index: 2;
    bottom: 10%;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  &__capture {
    font-size: 3rem;
    border: 0.3rem solid $color-white;
    width: 6rem;
    height: 6rem;
    border-radius: 6rem;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__flip {
    position: absolute;
    right: 6rem;
    height: 7.6rem;
    display: flex;
    align-items: center;
  }

  &__flip-rotate {
    font-size: 2.5rem;
  }

  &__review {
    padding: 0 2rem;

    @include responsive(sm) {
      max-width: $sm-max-width;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__review-box {
    text-align: center;
    background-color: $color-lighterlack;
  }

  &__review-image {
    max-width: 44%;
  }

  &__content {
    display: flex;
    justify-content: center;
    height: 100%;
    align-items: center;
  }

  &__error {
    text-align: center;
  }

  &__icon {
    background-color: $color-red;
    width: 6rem;
    height: 6rem;
    font-size: 4rem;
    border-radius: 6rem;
    line-height: 6rem;
    color: $color-black;
    display: inline-block;
    margin-bottom: 2rem;
  }

  p {
    margin-bottom: 2rem;
  }
}
