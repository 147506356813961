
.dock-list {
  padding: 0 2rem;

  &__item {
    display: flex;
    justify-content: space-between;
    padding: 0 2rem;
    margin-bottom: 2rem;

    @include responsive(sm) {
      max-width: $sm-max-width;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__name, &__postcode {
    font-size: 1.4rem;
    font-weight: $font-weight-bold;
  }

  &__distance {
    font-size: 1.2rem;
    color: $color-darkGre;
  }

  &__button {
    display: flex;
    align-items: center;
  }

}
