.preferred-dock {

  &__search {
    background-color: $color-darker-grey;
    padding: 2rem 2rem 1rem 2rem;
    position: relative;
  }

  &__search-icon {
    position: absolute;
    color: $color-darker-grey;
    top: 2.8rem;
    left: 2.8rem;
  }

  &__search-input {
    background-color: $color-lightGry !important;
    color: $color-black !important;
    display: block;
    border-radius: $global-radius !important;
    box-sizing: border-box;
    min-height: 2rem !important;
    font-size: 1.2rem;
    padding-left: 2.8rem !important;

    @include responsive(sm) {
      max-width: $sm-max-width;
      margin-left: auto;
      margin-right: auto;
    }

    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: $color-darker-grey;
      opacity: 1; /* Firefox */
    }

  }

  &__tabs {
    background-color: $color-darker-grey;
    display: flex;
    justify-content: space-evenly;
    border-bottom: 0.2rem solid $color-white;
    margin-bottom: 2rem;
  }

  &__tab {
    padding: 1rem 0;
    width: 40%;
    text-align: center;
    font-weight: bold;
    font-size: 1.2rem;
    text-transform: uppercase;
    margin-bottom: -0.2rem;

    &--active {
      border-bottom: 0.2rem solid $color-primary;
    }
  }

}
