
.step-header {
  position: relative;

  &__current-step {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    color: $color-white;
  }

  &__current-image {
    position: absolute;
    max-width: 8rem;
  }

  &__current-value {
    font-size: 4rem;
    line-height: 5rem;
  }

  &__out-of {
    font-size: 2rem;
    line-height: 4rem;
    vertical-align: bottom;
  }

  &__copy {
    padding: 2rem 4rem;
    @include responsive(sm) {
      max-width: $sm-max-width;
      margin-left: auto;
      margin-right: auto;
    }

    &--center {
      text-align: center;
    }

    &--small {
      font-size: 1.2rem;
    }

    &--bold {
      font-weight: $font-weight-bold;
    }
  }

  &__carousel {
    padding: 0 4rem;
    text-align: center;

    @include responsive(sm) {
      max-width: $sm-max-width;
      margin-left: auto;
      margin-right: auto;
    }

    // slick overrides
    .slick-dots {
      bottom: -50px;

      li button:before {
        color: $color-darkGre;
        opacity: 1;
        font-size: 1rem;
      }

      li.slick-active button:before {
        color: $color-primary;
      }
    }
  }

}
