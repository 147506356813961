.address {
  &__form {
    padding: 0rem 2rem;

    @include responsive(sm) {
      max-width: $sm-max-width;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__element {
    width: 100%;
    display: block;
    border-bottom: 1px solid $color-white;
    margin-bottom: 2rem;
  }

  &__tickbox {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
  }

  &__tickbox-label {
    line-height: 2rem;
  }

  &__tickbox-icon {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    border-radius: 1rem;
    border: 0.1rem solid $color-white;
    margin-right: 1rem;
  }
}
