
.login {
  background-color: $color-black;

  &.form {
    padding-top: 2rem;
  }

  &__logo {
    max-width: 200px;
  }

  &__background {
    background: url('../../images/bg-action.png') no-repeat center center;
    background-size: cover;
    height: 40%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
