
.header {
  background-color: $color-black;
  padding: 2rem;
  color: $color-white;
  position: relative;

  &--transparent {
    background: transparent;
  }

  &__title {
    text-align: center;
    font-weight: $font-weight-bold;
    width: 100%;
  }

  &__back {
    position: absolute;
    left: 1rem;
    top: 2rem;
    color: $color-white;
  }

  &__home, &__logout {
    position: absolute;
    top: 1rem;
    padding: 1rem;
    color: $color-white;
  }

  &__home {
    right: 4rem;
  }

  &__logout {
    right: 1rem;
  }
}
