.payment {
  height: 100%;
  //text-align: center;

  &__iframe {
    display: block;
    height: 100%;
    width: 100%;
    border: 0;

    @include responsive(sm) {
      max-width: $sm-max-width;
      margin-left: auto;
      margin-right: auto;
    }

  }
}
