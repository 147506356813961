.tariff-view {
  background-color: $color-white;
  height: calc(100% - 5.6rem);

  &__background {
    height: 14rem;
    background: url('../../images/banner-deep-action.png') no-repeat center center;
    background-size: cover;
  }

  &__pricing {
    margin-top: -7rem;
    display: flex;
    padding: 2rem;

    @include responsive(sm) {
      max-width: $sm-max-width;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__box {
    background-color: $color-darker-grey;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    padding: 2rem 0;
    border: 1px solid $color-darkGre;

    &:first-of-type {
      border-right: 1px solid $color-darkGre;
    }
  }

  &__box-title {
    text-transform: uppercase;
  }

  &__box-price {
    font-size: 3rem;
  }

  &__content {
    padding: 0 2rem 2rem 2rem;
    color: $color-black;

    @include responsive(sm) {
      max-width: $sm-max-width;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__text {
    margin-bottom: 2rem;
  }
}
