.complete {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    &__icon {
      background-color: $color-green;
      width: 6rem;
      height: 6rem;
      font-size: 4rem;
      border-radius: 6rem;
      line-height: 6rem;
      color: $color-black;
      display: inline-block;
      margin-bottom: 2rem;
    }

    &__title {
      font-weight: $font-weight-bold;
    }

    p {
      margin-bottom: 2rem;
    }
}
