
.tariff-list {
  padding: 0 2rem;

  @include responsive(sm) {
    max-width: $sm-max-width;
    margin-left: auto;
    margin-right: auto;
  }

  &__item {
    border-radius: 0.5rem;
    position: relative;
    border: 0.1rem solid $color-darkGre;
    margin-bottom: 2rem;
    overflow: hidden;
  }

  &__title {
    height: 7.4rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: url('../../images/banner-bike.png') no-repeat center center;
    background-size: cover;

    &--freq {
      background: url('../../images/banner-action.png') no-repeat center center;
    }
  }

  &__title-text {
    font-size: 2rem;
    font-weight: bold;
    padding: 0 2rem;
  }

  &__title-arrow {
    padding: 0 2rem;
  }

  &__image {
    position: absolute;
    z-index: -1;
    top: 0;
  }

  &__description {
    background-color: $color-darker-grey;
    display: flex;
    justify-content: space-evenly;
    align-content: center;
  }


  &__box {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    padding: 2rem 0;

    &:first-of-type {
      border-right: 1px solid $color-darkGre;
    }
  }

  &__box-title {
    text-transform: uppercase;
  }

  &__box-price {
    font-size: 3rem;
  }
}
