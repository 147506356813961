.dashboard {

  &__copy {
    padding: 2rem 4rem;

    &--center {
      text-align: center;
    }

    &--small {
      font-size: 1.2rem;
    }

    &--bold {
      font-weight: $font-weight-bold;
    }
  }

  p {
    margin-bottom: 2rem;
  }
}
